import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = () => {
  const [checkAdmintoken, setCheckAdmintoken] = useState(false);
  console.log("checkAdmintoken: ", checkAdmintoken);
  const [checkPstoken, setCheckPstoken] = useState(false);
  console.log("checkPstoken: ", checkPstoken);
  const [checksalestoken, setChecksalestoken] = useState(false);
  console.log("checksalestoken: ", checksalestoken);
  const [checkfmtoken, setCheckfmtoken] = useState(false);
  console.log("checkfmtoken: ", checkfmtoken);
  const [checkFttoken, setCheckFttoken] = useState(false);
  console.log("checkFttoken: ", checkFttoken);
  const [checkInfluencerToken, setCheckInfluencerToken] = useState(false);
  console.log("checkInfluencerToken: ", checkInfluencerToken);

  useEffect(() => {
    const updateTokensFromLocalStorage = () => {
      setCheckAdmintoken(localStorage.getItem("Admintoken") !== null);
      setCheckPstoken(localStorage.getItem("Pre_SalesToken") !== null);
      setChecksalestoken(localStorage.getItem("SalesToken") !== null);
      setCheckfmtoken(localStorage.getItem("FilingManagerToken") !== null);
      setCheckFttoken(localStorage.getItem("FilingTeamToken") !== null);
      setCheckInfluencerToken(localStorage.getItem("InfluencerToken") !== null);
    };

    updateTokensFromLocalStorage();

    // You can add more dependencies if needed
  }, []); // The empty dependency array ensures that this effect runs once on mount

  return (
    <>
      <div className="sidebar">
        <div className="image">
          {/* Add your logo image here */}
          <img src="../Assets/Images/logo.png" alt="Logo" />
        </div>

        {checkAdmintoken && (
          <>
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/activity">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Employee Manage</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link collapsed"
                        href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#leadManage"
                        aria-expanded="false"
                      >
                        <i className="fa-brands fa-servicestack" />
                        Leads Manage
                        <i className="fa fa-chevron-down float-chevron" />
                      </a>
                      <ul className="sub-menu2 collapse" id="leadManage">
                        <li className="nav-item">
                          <Link to="/Adminleadmanage">
                            <span className="sidebar-name">
                              Pre-sales Leads
                            </span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/Adminsalemanage">
                            Sales Team Leads
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/FillingMAnagerAdmin">
                            Filing Manager Leads
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/FillingTeamAdmin">
                            Filing Team Leads
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ccs-partner-li">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">CCS-Partner</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/campaign">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Campaign</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link collapsed"
                        href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#settings"
                        aria-expanded="false"
                      >
                        <i className="fa-brands fa-servicestack" />
                        Settings
                        <i className="fa fa-chevron-down float-chevron" />
                      </a>
                      <ul className="sub-menu2 collapse" id="settings">
                        <li className="nav-item">
                          <Link className="nav-link" to="/lead-type">
                            <i className="fa fa-users" aria-hidden="true" />
                            <span className="sidebar-name">Lead Type</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/lead-source">
                            <i className="fa fa-users" aria-hidden="true" />
                            <span className="sidebar-name">Lead Source</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/service-name">
                            <i className="fa fa-users" aria-hidden="true" />
                            <span className="sidebar-name">Service Name</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/leader-board">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Leader Board</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/analytics-board">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Analytics</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="modal"
                        data-bs-target="#influencerModal"
                      >
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name" style={{ fontSize: 14 }}>
                          CCS-Partner Onboarding Url
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/notes">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Notes</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}

        {checkPstoken && (
          <>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true" />
                <span className="sidebar-name">Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/leadmanage">
                <i className="fa fa-users" aria-hidden="true" />
                <span className="sidebar-name">Lead Management</span>
              </Link>
            </li> */}

            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/leadmanage">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Lead Management</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}

        {checksalestoken && (
          <>
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/leadAssign">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Lead Management</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="modal"
                        data-bs-target="#influencerModal"
                      >
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name" style={{ fontSize: 14 }}>
                          CCS-Partner Onboarding Url
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}

        {checkfmtoken && (
          <>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true" />
                <span className="sidebar-name">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/FilingManager">
                <i className="fa fa-users" aria-hidden="true" />
                <span className="sidebar-name">Lead Management</span>
              </Link>
            </li> */}
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/FilingManager">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Lead Management</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}

        {checkFttoken && (
          <>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                <i className="fa fa-home" aria-hidden="true" />
                <span className="sidebar-name">Dashboard</span>
              </Link>
            </li> */}
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/FillingTeam">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Lead Management</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}

        {checkInfluencerToken && (
          <>
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="navcontainer">
                <div className="navbar-collapse">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="fa-solid fa-home" aria-hidden="true" />
                        <span className="sidebar-name">Dashboard</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/influencer-leads">
                        <i className="fa fa-users" aria-hidden="true" />
                        <span className="sidebar-name">Lead Management</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </>
        )}
      </div>

      {/* Modal Structure */}
      <div
        className="modal fade"
        id="influencerModal"
        tabindex="-1"
        aria-labelledby="logoutModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="influencerModalLabel">
                CCS-Partner onboarding
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <a
                  href="/ccs-partner"
                  // href="https://checkcheckservices.in/influencer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://checkcheckservices.in/ccs-partner
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
