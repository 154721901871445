import React, { useEffect, useState } from "react";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import checklogin from "./checkLogin";
import axios from "axios";
import From from "./From";
import host from "./utils";
import icondash1 from "../Assets/Images/icondash1.png";

function Dashboard() {
  const initialTimezone = localStorage.getItem('timezone') || 'India';
  const [timezone, setTimezone] = useState(initialTimezone);
  const [dateTime, setDateTime] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [allLeads, setAllLeads] = useState(0);
  const [checksalestoken, setCheckSalesToken] = useState(null);
  const [checkfmtoken, setCheckFMToken] = useState(null);
  const [checkPstoken, setCheckPSToken] = useState(null);
  const [checkFttoken, setCheckFTToken] = useState(null);
  const [checkInfluencerToken, setCheckInfluencerToken] = useState(null);
  const [checkAdmintoken, setCheckAdminToken] = useState(null);
  const [psData, setPsData] = useState(0);
  const [salesFreshData, setSalesFreshData] = useState(0);
  const [salesPendingData, setSalesPendingData] = useState(0);
  const [salesClosingData, setSalesClosingData] = useState(0);
  const [salesConvertData, setSalesConvertData] = useState(0);
  const [salesTodayFollowUpData, setSalesTodayFollowUpData] = useState(0);
  const [adminSalesFreshData, setAdminSalesFreshData] = useState(0);
  const [adminSalesPendingData, setAdminSalesPendingData] = useState(0);
  const [adminSalesClosingData, setAdminSalesClosingData] = useState(0);
  const [adminSalesConvertData, setAdminSalesConvertData] = useState(0);
  const [adminSalesTodayFollowUpData, setAdminSalesTodayFollowUpData] = useState(0);
  const [managerStudentFreshCount, setManagerStudentFreshCount] = useState(0);
  const [managerStudentVisaFreshCount, setManagerStudentVisaFreshCount] = useState(0);
  const [managerStudentVisaRefusalCount, setManagerStudentVisaRefusalCount] = useState(0);
  const [managerSpousalOpenWorkPermitFreshCount, setManagerSpousalOpenWorkPermitFreshCount] = useState(0);
  const [managerSpousalOpenWorkPermitRefusalCount, setManagerSpousalOpenWorkPermitRefusalCount] = useState(0);
  const [managerSpousalSponsorshipFreshCount, setManagerSpousalSponsorshipFreshCount] = useState(0);
  const [managerSpousalSponsorshipRefusalCount, setManagerSpousalSponsorshipRefusalCount] = useState(0);
  const [managerWorkPermitFreshCount, setManagerWorkPermitFreshCount] = useState(0);
  const [managerWorkPermitRefusalCount, setManagerWorkPermitRefusalCount] = useState(0);
  const [managerTouristVisaFreshCount, setManagerTouristVisaFreshCount] = useState(0);
  const [managerTouristVisaRefusalCount, setManagerTouristVisaRefusalCount] = useState(0);
  const [managerRNIPFreshCount, setManagerRNIPFreshCount] = useState(0);
  const [managerRNIPRefusalCount, setManagerRNIPRefusalCount] = useState(0);
  const [managerVisaExtensionsFreshCount, setManagerVisaExtensionsFreshCount] = useState(0);
  const [managerVisaExtensionsRefusalCount, setManagerVisaExtensionsRefusalCount] = useState(0);
  const [managerFederalFilingFreshCount, setManagerFederalFilingFreshCount] = useState(0);
  const [managerFederalFilingRefusalCount, setManagerFederalFilingRefusalCount] = useState(0);
  const [managerPNPFreshCount, setManagerPNPFreshCount] = useState(0);
  const [managerPNPRefusalCount, setManagerPNPRefusalCount] = useState(0);
  const [adminManagerStudentFreshCount, setAdminManagerStudentFreshCount] = useState(0);
  const [adminManagerStudentVisaFreshCount, setAdminManagerStudentVisaFreshCount] = useState(0);
  const [adminManagerStudentVisaRefusalCount, setAdminManagerStudentVisaRefusalCount] = useState(0);
  const [adminManagerSpousalOpenWorkPermitFreshCount, setAdminManagerSpousalOpenWorkPermitFreshCount] = useState(0);
  const [adminManagerSpousalOpenWorkPermitRefusalCount, setAdminManagerSpousalOpenWorkPermitRefusalCount] = useState(0);
  const [adminManagerSpousalSponsorshipFreshCount, setAdminManagerSpousalSponsorshipFreshCount] = useState(0);
  const [adminManagerSpousalSponsorshipRefusalCount, setAdminManagerSpousalSponsorshipRefusalCount] = useState(0);
  const [adminManagerWorkPermitFreshCount, setAdminManagerWorkPermitFreshCount] = useState(0);
  const [adminManagerWorkPermitRefusalCount, setAdminManagerWorkPermitRefusalCount] = useState(0);
  const [adminManagerTouristVisaFreshCount, setAdminManagerTouristVisaFreshCount] = useState(0);
  const [adminManagerTouristVisaRefusalCount, setAdminManagerTouristVisaRefusalCount] = useState(0);
  const [adminManagerRNIPFreshCount, setAdminManagerRNIPFreshCount] = useState(0);
  const [adminManagerRNIPRefusalCount, setAdminManagerRNIPRefusalCount] = useState(0);
  const [adminManagerVisaExtensionsFreshCount, setAdminManagerVisaExtensionsFreshCount] = useState(0);
  const [adminManagerVisaExtensionsRefusalCount, setAdminManagerVisaExtensionsRefusalCount] = useState(0);
  const [adminManagerFederalFilingFreshCount, setAdminManagerFederalFilingFreshCount] = useState(0);
  const [adminManagerFederalFilingRefusalCount, setAdminManagerFederalFilingRefusalCount] = useState(0);
  const [adminManagerPNPFreshCount, setAdminManagerPNPFreshCount] = useState(0);
  const [adminManagerPNPRefusalCount, setAdminManagerPNPRefusalCount] = useState(0);
  const [todayFillingProcessFolowUpCount, setTodayFillingProcessFolowUpCount] = useState(0);
  const [caseInitiateCount, setCaseInitiateCount] = useState(0);
  const [fileInitiateCount, setFileInitiateCount] = useState(0);
  const [docsReceivedCount, setDocsReceivedCount] = useState(0);
  const [sopPreprationCount, setSopPreprationCount] = useState(0);
  const [sopLettersCount, setSopLettersCount] = useState(0);
  const [confirmRecievedCount, setConfirmRecievedCount] = useState(0);
  const [fileSentCount, setFileSentCount] = useState(0);
  const [visaApprovedCount, setVisaApprovedCount] = useState(0);
  const [visaRefusalCount, setVisaRefusalCount] = useState(0);
  const [adminTodayFillingProcessFolowUpCount, setAdminTodayFillingProcessFolowUpCount] = useState(0);
  const [adminCaseInitiateCount, setAdminCaseInitiateCount] = useState(0);
  const [adminFileInitiateCount, setAdminFileInitiateCount] = useState(0);
  const [adminDocsReceivedCount, setAdminDocsReceivedCount] = useState(0);
  const [adminSopPreprationCount, setAdminSopPreprationCount] = useState(0);
  const [adminSopLettersCount, setAdminSopLettersCount] = useState(0);
  const [adminConfirmRecievedCount, setAdminConfirmRecievedCount] = useState(0);
  const [adminFileSentCount, setAdminFileSentCount] = useState(0);
  const [adminVisaApprovedCount, setAdminVisaApprovedCount] = useState(0);
  const [adminVisaRefusalCount, setAdminVisaRefusalCount] = useState(0);
  const [salesRevenueLeadData, setSalesRevenueLeadData] = useState(0);
  const [salesToatlRevenueAmount, setSalesToatlRevenueAmount] = useState(0);
  const [adminSalesConvertLeads, setAdminSalesRevenueLeadData] = useState(0);
  const [totalRevenueAmount, setToatlRevenueAmount] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [todayFillingManagerFolowUpCount, setTodayFillingManagerFolowUp] = useState(0);
  const [todayFillingTeamFolowUpCount, setTodayFillingTeamFolowUp] = useState(0);
  const [influencerCount, setInfluencerCount] = useState(0);
  const [influencerWallet, setInfluencerWallet] = useState(0);
  const [influencerUnpaidLeads, setInfluencerUnpaidLeads] = useState(0);
  const [influencerPaidWallet, setInfluencerPaidWallet] = useState(0);
  const [influencerLeadsCount, setInfluencerLeadsCount] = useState(0);
  const [influencerLeadsPendingCount, setInfluencerLeadsPendingCount] = useState(0);
  const [influencerLeadsConvertCount, setInfluencerLeadsConvertCount] = useState(0);
  const [submited , setSubmited] = useState()

  useEffect(() => {
    checklogin();
    getLeaderBoard();
    const salesToken = localStorage.getItem("SalesToken");
    const fmToken = localStorage.getItem("FilingManagerToken");
    const psToken = localStorage.getItem("Pre_SalesToken");
    const ftToken = localStorage.getItem("FilingTeamToken");
    const influencerToken = localStorage.getItem("InfluencerToken");
    const adminToken = localStorage.getItem("Admintoken");

    setCheckSalesToken(salesToken);
    setCheckFMToken(fmToken);
    setCheckPSToken(psToken);
    setCheckFTToken(ftToken);
    setCheckInfluencerToken(influencerToken);
    setCheckAdminToken(adminToken);
    getSalesRevenue();
    adminRevenueBoard();
    todayManagerFollowUp();
    todayTeamFollowUp();
    getInfluencerCount();
    getInfluencerWallet();
    getInfluencerUnpaidWallet();
    getInfluencerPaidWallet();
    getInfluencerLeadCount();
  }, []);

  useEffect(() => {
    getAdminSalesData();
    getSalesData();
    getSalesRevenue();
    adminRevenueBoard();
    getLeaderBoard();
  }, [startDate, endDate]);

  useEffect(() => {
    const timerID = setInterval(() => {
      const currentTime = displayTime(timezone); // Assuming displayTime returns the current time
      if (currentTime) {
        setDateTime(currentTime); // Set the dateTime state with the current time
        const timePart = currentTime.split(" at ")[1]; // Extracting the time part
        setCurrentTime(timePart.split(":").slice(0, 3).join(":"));
      }
    }, 1000);

    return () => clearInterval(timerID);
  }, [timezone]);

  const displayTime = (selectedTimezone) => {
    const date = new Date();
    if (selectedTimezone === 'India') {
      const options = { timeZone: 'Asia/Kolkata', hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleString('en-US', options);
    } else if (selectedTimezone === 'Canada') {
      const options = { timeZone: 'America/Toronto', hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleString('en-US', options);
    }
  };

  const handleChange = (event) => {
    setTimezone(event.target.value);
    localStorage.setItem('timezone', event.target.value);
  };

  useEffect(() => {
    // Get the current date
    const today = new Date();
    // Set the start date to the first day of the current month
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    // Set the end date to the last day of the current month
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    // Format dates as strings (YYYY-MM-DD)
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    // Set default values to state
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getAllLeads = async () => {
    const response = await axios.get(`${host}/all-leadcount`);
    if (response.status == 200) {
      setAllLeads(response.data.data);
    }
  };

  const getPsData = async () => {
    const response = await axios.get(`${host}/presales-freshleadcount`);
    if (response.status == 200) {
      setPsData(response.data.data);
    }
  };

  const formatLeadDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two digits for month
    const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits for day
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getSalesData = async () => {
    const date = new Date();
    const item = {
      id: localStorage.getItem('userId'),
      start_date: startDate,
      end_date: endDate,
      current_date: `${String(date.getMonth() + 1).toString().padStart(1, '0')}/${String(date.getDate().toString().padStart(1, '0'))}/${date.getFullYear()}`
    }
    const response = await axios.post(`${host}/getlead-statuscount`, item);
    if (response.status == 200) {
      setSalesFreshData(response.data.data);
      setSalesPendingData(response.data.pendingCount);
      setSalesClosingData(response.data.closeCount);
      setSalesTodayFollowUpData(response.data.todayFollowupCount);
    }
  };

  const getAdminSalesData = async () => {
    const date = new Date();
    const item = {
      id: '',
      start_date: startDate,
      end_date: endDate,
      current_date: `${String(date.getMonth() + 1)}/${String(date.getDate())}/${date.getFullYear()}`
    }
    const response = await axios.post(`${host}/getlead-statuscount`, item);
    if (response.status == 200) {
      setAdminSalesFreshData(response.data.data);
      setAdminSalesPendingData(response.data.pendingCount);
      setAdminSalesClosingData(response.data.closeCount);
      setAdminSalesTodayFollowUpData(response.data.todayFollowupCount);
    }
  };

  const getFilingManagerData = async () => {
    const response = await axios.get(`${host}/getlead-fillingmanagerCount`, {
      params: {
        id: localStorage.getItem('userId')
      }
    });
    if (response.status == 200) {
      setManagerStudentFreshCount(response.data.data.freshLeadsCount);
      setManagerStudentVisaFreshCount(response.data.data.studentVisaFreshCount);
      setManagerStudentVisaRefusalCount(response.data.data.studentVisaRefusalCount);
      setManagerSpousalOpenWorkPermitFreshCount(response.data.data.spousalOpenWorkPermitFreshCount);
      setManagerSpousalOpenWorkPermitRefusalCount(response.data.data.spousalOpenWorkPermitRefusalCount);
      setManagerSpousalSponsorshipFreshCount(response.data.data.spousalSponsorshipFreshCount);
      setManagerSpousalSponsorshipRefusalCount(response.data.data.spousalSponsorshipRefusalCount);
      setManagerWorkPermitFreshCount(response.data.data.workPermitFreshCount);
      setManagerWorkPermitRefusalCount(response.data.data.workPermitRefusalCount)
      setManagerTouristVisaFreshCount(response.data.data.touristVisaFreshCount);
      setManagerTouristVisaRefusalCount(response.data.data.touristVisaRefusalCount);
      setManagerRNIPFreshCount(response.data.data.RNIPFreshCount);
      setManagerRNIPRefusalCount(response.data.data.RNIPRefusalCount);
      setManagerVisaExtensionsFreshCount(response.data.data.visaExtensionsFreshCount);
      setManagerVisaExtensionsRefusalCount(response.data.data.visaExtensionsRefusalCount);
      setManagerFederalFilingFreshCount(response.data.data.federalFilingFreshCount);
      setManagerFederalFilingRefusalCount(response.data.data.federalFilingRefusalCount);
      setManagerPNPFreshCount(response.data.data.PNPFreshCount);
      setManagerPNPRefusalCount(response.data.data.PNPRefusalCount);
      setTodayFillingProcessFolowUpCount(response.data.data.todayFillingProccessFollowUpCount);
      setCaseInitiateCount(response.data.data.caseInitiateCount);
      setFileInitiateCount(response.data.data.fileInitiateCount);
      setDocsReceivedCount(response.data.data.docsReceivedCount);
      setSopPreprationCount(response.data.data.sopPreprationCount);
      setSopLettersCount(response.data.data.sopLettersCount);
      setConfirmRecievedCount(response.data.data.confirmRecievedCount);
      setFileSentCount(response.data.data.fileSentCount);
      setVisaApprovedCount(response.data.data.visaApprovedCount);
      setVisaRefusalCount(response.data.data.visaRefusalCount);
    }
  };

  const getAdminFilingManagerData = async () => {
    const response = await axios.get(`${host}/getlead-fillingmanagerCount`, {
      params: {
        id: ''
      }
    });
    if (response.status == 200) {
      setAdminManagerStudentFreshCount(response.data.data.freshLeadsCount);
      setAdminManagerStudentVisaFreshCount(response.data.data.studentVisaFreshCount);
      setAdminManagerStudentVisaRefusalCount(response.data.data.studentVisaRefusalCount);
      setAdminManagerSpousalOpenWorkPermitFreshCount(response.data.data.spousalOpenWorkPermitFreshCount);
      setAdminManagerSpousalOpenWorkPermitRefusalCount(response.data.data.spousalOpenWorkPermitRefusalCount);
      setAdminManagerSpousalSponsorshipFreshCount(response.data.data.spousalSponsorshipFreshCount);
      setAdminManagerSpousalSponsorshipRefusalCount(response.data.data.spousalSponsorshipRefusalCount);
      setAdminManagerWorkPermitFreshCount(response.data.data.workPermitFreshCount);
      setAdminManagerWorkPermitRefusalCount(response.data.data.workPermitRefusalCount)
      setAdminManagerTouristVisaFreshCount(response.data.data.touristVisaFreshCount);
      setAdminManagerTouristVisaRefusalCount(response.data.data.touristVisaRefusalCount);
      setAdminManagerRNIPFreshCount(response.data.data.RNIPFreshCount);
      setAdminManagerRNIPRefusalCount(response.data.data.RNIPRefusalCount);
      setAdminManagerVisaExtensionsFreshCount(response.data.data.visaExtensionsFreshCount);
      setAdminManagerVisaExtensionsRefusalCount(response.data.data.visaExtensionsRefusalCount);
      setAdminManagerFederalFilingFreshCount(response.data.data.federalFilingFreshCount);
      setAdminManagerFederalFilingRefusalCount(response.data.data.federalFilingRefusalCount);
      setAdminManagerPNPFreshCount(response.data.data.PNPFreshCount);
      setAdminManagerPNPRefusalCount(response.data.data.PNPRefusalCount);
    }
  };

  const getFilingTeamData = async () => {
    const response = await axios.get(`${host}/getlead-fillingcasecount`, {
      params: {
        id: localStorage.getItem('userId')
      }
    });
    if (response.status == 200) {
      // setTodayFillingProcessFolowUpCount(todayFillingProccessFollowUpCount);
      setCaseInitiateCount(response.data.data.caseInitiateCount);
      setFileInitiateCount(response.data.data.fileInitiateCount);
      setDocsReceivedCount(response.data.data.docsReceivedCount);
      setSopPreprationCount(response.data.data.sopPreprationCount);
      setSopLettersCount(response.data.data.sopLettersCount);
      setConfirmRecievedCount(response.data.data.confirmRecievedCount);
      setFileSentCount(response.data.data.fileSentCount);
      setVisaApprovedCount(response.data.data.visaApprovedCount);
      setVisaRefusalCount(response.data.data.visaRefusalCount);
    }
  };

  const getAdminFilingTeamData = async () => {
    const response = await axios.get(`${host}/getlead-fillingcasecount`, {
      params: {
        id: ''
      }
    });
    console.log("response",response)

    if (response.status == 200) {
      console.log("fefefefefefefefefefefefefefefefefefefe",response.data.data);
      // setAdminTodayFillingProcessFolowUpCount(todayFillingProccessFollowUpCount);
      setAdminCaseInitiateCount(response.data.data.caseInitiateCount);
      setAdminFileInitiateCount(response.data.data.fileInitiateCount);
      setAdminDocsReceivedCount(response.data.data.docsReceivedCount);
      setAdminSopPreprationCount(response.data.data.sopPreprationCount);
      setAdminSopLettersCount(response.data.data.sopLettersCount);
      setAdminConfirmRecievedCount(response.data.data.confirmRecievedCount);
      setAdminFileSentCount(response.data.data.fileSentCount);
      setAdminVisaApprovedCount(response.data.data.visaApprovedCount);
      setAdminVisaRefusalCount(response.data.data.visaRefusalCount);
    }
  };

  const getSalesRevenue = async () => {
    if (startDate != '' && endDate != '') {
      const response = await axios.get(`${host}/getrevenue-boardcount`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          id: localStorage.getItem('userId')
        }
      });
      if (response.status == 200) {
        const filteredData = response.data.data.map(val => {
          const dates = val.Payment_Proof_Date;
          const amounts = val.Amount_Paid;
          const filteredDatesAndAmounts = dates
            .map((date, idx) => ({ date, amount: amounts[idx] }))
            .filter(pair => pair.date >= startDate && pair.date <= endDate);
          return {
            ...val,
            filteredDatesAndAmounts
          };
        });
        let totalReceivedPayment = 0; // Initialize sum outside the loop

        filteredData.forEach(Value => {
          Value.filteredDatesAndAmounts.forEach(pair => {
            totalReceivedPayment += parseInt(pair.amount); // Update sum inside the loop
          });
        });

        const validCounts = response.data.data.filter(val => {
          const firstDate = new Date(val.Payment_Proof_Date[0]);
          return firstDate >= new Date(startDate);
        })
        setSalesRevenueLeadData(validCounts.length);
        setSalesToatlRevenueAmount(totalReceivedPayment);
      }
    }
  };

  const adminRevenueBoard = async () => {
    if (startDate != '' && endDate != '') {
      const response = await axios.get(`${host}/getadminrevenue-boardcount`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          id: ""
        }
      });
      if (response.data.error == false) {
        const filteredData = response.data.data.map(val => {
          const dates = val.Payment_Proof_Date;
          const amounts = val.Amount_Paid;
          const filteredDatesAndAmounts = dates
            .map((date, idx) => ({ date, amount: amounts[idx] }))
            .filter(pair => pair.date >= startDate && pair.date <= endDate);
          return {
            ...val,
            filteredDatesAndAmounts
          };
        });
        let totalReceivedPayment = 0; // Initialize sum outside the loop

        filteredData.forEach(Value => {
          Value.filteredDatesAndAmounts.forEach(pair => {
            totalReceivedPayment += parseInt(pair.amount); // Update sum inside the loop
          });
        });

        const validCounts = response.data.data.filter(val => {
          const firstDate = new Date(val.Payment_Proof_Date[0]);
          return firstDate >= new Date(startDate);
        })
        setAdminSalesRevenueLeadData(validCounts.length);
        setToatlRevenueAmount(totalReceivedPayment);
      }
    }
  };

  const todayManagerFollowUp = async () => {
    try {
      const date = new Date();
      const item = {
        id: localStorage.getItem('userId'),
        role: "manager",
        current_date: `${String(date.getMonth() + 1).toString().padStart(1, '0')}/${String(date.getDate().toString().padStart(1, '0'))}/${date.getFullYear()}`
      }
      const response = await axios.post(`${host}/getcase-todaycount`, item);
      if (response.status == 200) {
        setTodayFillingManagerFolowUp(response.data.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const todayTeamFollowUp = async () => {
    try {
      const date = new Date();
      const item = {
        id: localStorage.getItem('userId'),
        role: "team",
        current_date: `${String(date.getMonth() + 1).toString().padStart(1, '0')}/${String(date.getDate().toString().padStart(1, '0'))}/${date.getFullYear()}`
      }
      const response = await axios.post(`${host}/getcase-todaycount`, item);
      if (response.status == 200) {
        setTodayFillingTeamFolowUp(response.data.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const getInfluencerCount = async () => {
    const response = await axios.get(`${host}/influencer-list`);
    if (response.data.error == false) {
      setInfluencerCount(response.data.data.length)
    } else {
      console.error("Failed to fetch data");
    }
  };

  const getLeaderBoard = async () => {
    try {
      const item = {
        start_date: startDate,
        end_date: endDate
      }
      const response = await axios.post(`${host}/leader-board`, item);
      if (response.data.error == false) {
        const filteredData = response.data.data.map(val => {
          const filteredLeads = val.leads.map(lead => {
            const dates = lead.Payment_Proof_Date;
            const amounts = lead.Amount_Paid;
            const filteredDatesAndAmounts = dates
              .map((date, idx) => ({ date, amount: amounts[idx] }))
              .filter(pair => pair.date >= startDate && pair.date <= endDate);

            return {
              ...lead,
              filteredDatesAndAmounts
            };
          });

          const totalAmountPaid = filteredLeads.reduce((total, lead) => {
            return total + lead.filteredDatesAndAmounts.reduce((sum, pair) => sum + Number(pair.amount), 0);
          }, 0);

          if (filteredLeads.length > 0) {
            val.leads = filteredLeads
            val.totalAmountPaid = totalAmountPaid
            return val;
          } else {
            return val;
          }
        })

        const validCounts = response.data.data.filter(val => {
          const filteredLeads = val.leads.filter(value => {
            const firstDate = new Date(value.Payment_Proof_Date[0]);
            return firstDate >= new Date(startDate);
          });
          if (filteredLeads.length > 0) {
            val.leadsCount = filteredLeads.length;
            return true;
          } else {
            return false;
          }
        })
        setLeaderboardData(validCounts);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const getInfluencerWallet = async () => {
    const response = await axios.get(`${host}/influencer-wallet`, {
      params: {
        userId: localStorage.getItem('userId')
      }
    });
    if (response.data.error == false) {
      setInfluencerWallet(response.data.data)
    } else {
      console.error("Failed to fetch data");
    }
  };

  const getInfluencerUnpaidWallet = async () => {
    const response = await axios.get(`${host}/influencer-unpaidwallet`, {
      params: {
        userId: localStorage.getItem('userId')
      }
    });
    if (response.data.error == false) {
      setInfluencerUnpaidLeads(response.data.data)
    } else {
      console.error("Failed to fetch data");
    }
  };

  const getInfluencerPaidWallet = async () => {
    const response = await axios.get(`${host}/influencer-paidwallet`, {
      params: {
        userId: localStorage.getItem('userId')
      }
    });
    if (response.data.error == false) {
      setInfluencerPaidWallet(response.data.data)
    } else {
      console.error("Failed to fetch data");
    }
  };

  const getInfluencerLeadCount = async () => {
    const response = await axios.get(`${host}/influencer-leadcount`, {
      params: {
        userId: localStorage.getItem('userId')
      }
    });
    if (response.data.error == false) {
      setInfluencerLeadsCount(response.data.data);
      setInfluencerLeadsPendingCount(response.data.pendingLeads);
      setInfluencerLeadsConvertCount(response.data.convertLeads);
    } else {
      console.error("Failed to fetch data");
    }
  };

  return (
    <>
      <CustomNavbar />
      <Sidebar />

      <div className="page-wrapper">
        <div className="page-content">

          <div style={styles.main}>
            <div>
              <label htmlFor="timezone" style={styles.label}>Select Time Zone:</label>
              <select id="timezone" style={styles.select} onChange={handleChange} value={timezone}>
                <option value="India">India</option>
                <option value="Canada">Canada</option>
              </select>
              <div style={styles.dateTime}>{`Date and Time in ${timezone}: ${dateTime}`}</div>
            </div>

            <div style={styles.wish}>
              Hello!<br />{`${currentTime}` >= '05:00:00' && `${currentTime}` <= '12:00:00' ? "Good Morning" : `${currentTime}` >= '12:00:00' && `${currentTime}` <= '18:00:00' ? "Good Afternoon" : `${currentTime}` >= '18:00:00' && `${currentTime}` <= '22:00:00' ? "Good Evening" : "Good Night"}
            </div>
          </div>

          {checkInfluencerToken == null ? (<div className="row">
            <div className="col-md-12">
              <div className="wrap mt-0">
                <div style={{ display: "flex" }}>
                  <div>
                    <label>Start Date:</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="datepick"
                    />
                  </div>
                  <div>
                    <label>End Date:</label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="datepick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>) : (<div className="row"></div>)}

          {checkInfluencerToken == null ? (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>All Leads</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/all-leads">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getAllLeads}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">All Leads</p>
                      </div>
                      <h4 className="amt">{allLeads}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>) : (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>CCS-partner Earnings</h6>
                <div class="line"></div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-wallet">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Total Earnings
                        </p>
                      </div>
                      <h4 className="amt">${influencerWallet}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-paidwallet">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Paid Amount
                        </p>
                      </div>
                      <h4 className="amt">${influencerPaidWallet}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-unpaidwallet">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Outstanding Balance
                        </p>
                      </div>
                      <h4 className="amt">${influencerUnpaidLeads}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>CCS-partner Leads</h6>
                <div class="line"></div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-leads">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          All Leads
                        </p>
                      </div>
                      <h4 className="amt">{influencerLeadsCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-pendingleads">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Pending Leads{" "}
                        </p>
                      </div>
                      <h4 className="amt">{influencerLeadsPendingCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <a href="/influencer-convertleads">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Convert Leads{" "}
                        </p>
                      </div>
                      <h4 className="amt">{influencerLeadsConvertCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>)}

          {checkAdmintoken == null ? '' : (<div className="row">

            <div class="col-md-12">
              <div className="separator">
                <h6>CCS-partner Board</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/all-influencer">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Total CCS-partner</p>
                      </div>
                      <h4 className="amt">{influencerCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Revenue Board</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/adminrevenue-convertleads">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Leads</p>
                        <p className="dist-title">Total Received Payement</p>
                      </div>
                      <div>
                        <h4 className="dist-title">{adminSalesConvertLeads}</h4>
                        <h4 className="dist-title">${totalRevenueAmount}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Pre-Sales Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <a href="/fresh-leads">
              <div className="col-md-3 mb-3">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getPsData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{psData}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <div class="col-md-12">
              <div className="separator">
                <h6>Sales Team Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/adminsales-freshleads">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getAdminSalesData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{adminSalesFreshData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/adminsales-pendingleads">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Follow-Ups</p>
                      </div>
                      <h4 className="amt">{adminSalesPendingData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminsales-closeleads">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Close Leads </p>
                      </div>
                      <h4 className="amt">{adminSalesClosingData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminsales-convertleads">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Convert Leads </p>
                      </div>
                      <h4 className="amt">{adminSalesConvertLeads}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminsales-todayleads">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Today Follow-Ups Leads</p>
                      </div>
                      <h4 className="amt">{adminSalesTodayFollowUpData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Filing Manager Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/adminmanager-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getAdminFilingManagerData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{adminManagerStudentFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/adminmanagerstudentvisa-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Student Visa - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerStudentVisaFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerstudentvisa-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Student Visa - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerStudentVisaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerspousal-openworkfresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Open Work Permit - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerSpousalOpenWorkPermitFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerspousal-openworkRefusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Open Work Permit - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerSpousalOpenWorkPermitRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerspousal-sponsorshipfresh">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Sponsorship - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerSpousalSponsorshipFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerspousal-sponsorshiprefusal">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Sponsorship - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerSpousalSponsorshipRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerworkpermit-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Work Permit - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerWorkPermitFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerworkpermit-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Work Permit - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerWorkPermitRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagertouristvisa-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Tourist Visa - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerTouristVisaFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagertouristvisa-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Tourist Visa - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerTouristVisaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerrnip-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">RNIP (Rural and Northern Immigration Pilot) - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerRNIPFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerrnip-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">RNIP (Rural and Northern Immigration Pilot) - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerRNIPRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagervisaextensions-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa Extensions - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerVisaExtensionsFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagervisaextensions-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa Extensions - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerVisaExtensionsRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerfederalfiling-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Federal Filing - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerFederalFilingFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerfederalfiling-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Federal Filing - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerFederalFilingRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerpnp-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">PNP (Provincial Nominee Program) Application. - Fresh</p>
                      </div>
                      <h4 className="amt">{adminManagerPNPFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminmanagerpnp-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">PNP (Provincial Nominee Program) Application. - Refusal</p>
                      </div>
                      <h4 className="amt">{adminManagerPNPRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Filling Case Stages</h6>
                <div class="line"></div>
              </div>
            </div>

            {/* <div className="col-md-3 mb-3">
              <a href="/adminfillingtoday-followup">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div >
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Filling Case Stages</p>
                      </div>
                      <h4 className="amt">{todayFillingProcessFolowUpCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div> */}

            <div className="col-md-3 mb-3">
              <a href="/adminfillingcase-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getAdminFilingTeamData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Case Initiated Date and Notes
                        </p>
                      </div>
                      <h4 className="amt">{adminCaseInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingfile-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File Initiated or docs checklist sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{adminFileInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingdocs-received">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Docs received & Pending docs sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{adminDocsReceivedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingsop-letterprepration">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          SOP Or letters prepration & Forms prep
                        </p>
                      </div>
                      <h4 className="amt">{adminSopPreprationCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingsop-lettersent">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          SOP or Letters sent to client
                        </p>
                      </div>
                      <h4 className="amt">{adminSopLettersCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingconfirmation-received">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Confirmation received on SOP , letters and docs, Forms
                          confirm...
                        </p>
                      </div>
                      <h4 className="amt">{adminConfirmRecievedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

              <div className="col-md-3 mb-3">
                <a href="/adminfillingfile-sent">
                  <div className="card radius-10  border-0 border-4 cardbg-pink">
                    <div className="card-body">
                      <div className="d-flx-dist">
                        <div>
                          <div className="dgl2">
                            <img src={icondash1} alt="img" />
                          </div>
                          <p className="dist-title">
                            File sent for Upload or submission confirm...
                          </p>
                        </div>
                        <h4 className="amt">{adminFileSentCount}</h4>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

            <div className="col-md-3 mb-3">
              <a href="/submitted">
                <div className="card radius-10  border-0 border-4 cardbg-orange">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File Submited
                        </p>
                      </div>
                      <h4 className="amt">{adminFileSentCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingvisa-approved">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa approved</p>
                      </div>
                      <h4 className="amt">{adminVisaApprovedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/adminfillingvisa-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa refusal</p>
                      </div>
                      <h4 className="amt">{adminVisaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>)}

          {checkPstoken == null ? '' : (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>Pre-Sales Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/fresh-leads">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getPsData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{psData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>)}

          {checksalestoken == null ? '' : (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>Revenue Board</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href={`/sales-leadboardlead?id=${localStorage.getItem('userId')}`}>
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Leads</p>
                        <p className="dist-title">Total Received Payement</p>
                      </div>
                      <div>
                        <h4 className="dist-title">{salesRevenueLeadData}</h4>
                        <h4 className="dist-title">${salesToatlRevenueAmount}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Leader Board</h6>
                <div class="line"></div>
              </div>
            </div>
            {leaderboardData.map(val => (
              val.role._id !== localStorage.getItem('userId') && val.role.job_role === "Sales" && val.leads.length != 0 ? (
                <div key={val.role._id} className="col-md-3 mb-3">
                  <a href={`/sales-leadboardlead?id=${val.role._id}`}>
                    <div className="card radius-10  border-0 border-4 cardbg-orange ">
                      <div className="card-body">
                        <div className="d-flx-dist">
                          <div>
                            <div className="dgl2">
                              <img src={icondash1} alt="img" />
                            </div>
                            <p className="dist-title">Sales Executive</p>
                            <p className="dist-title">Leads</p>
                            <p className="dist-title">Total Received Payment</p>
                          </div>
                          <div>
                            <h4 className="dist-title">{val.role.first_name} {val.role.last_name}</h4>
                            <h4 className="dist-title">{val.leadsCount}</h4>
                            <h4 className="dist-title">${val.totalAmountPaid}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ) : null
            ))}

            <div class="col-md-12">
              <div className="separator">
                <h6>Sales Team Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/sales-freshleads">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getSalesData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{salesFreshData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/sales-pendingLeads">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Follow-Ups</p>
                      </div>
                      <h4 className="amt">{salesPendingData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/sales-closeleads">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Close Leads </p>
                      </div>
                      <h4 className="amt">{salesClosingData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/sales-convertleads">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Convert Leads </p>
                      </div>
                      <h4 className="amt">{salesRevenueLeadData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/sales-todayleads">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Today Follow-Ups Leads</p>
                      </div>
                      <h4 className="amt">{salesTodayFollowUpData}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

          </div>)}

          {checkfmtoken == null ? '' : (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>Filing Manager Stage</h6>
                <div class="line"></div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/manager-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getFilingManagerData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Fresh Leads</p>
                      </div>
                      <h4 className="amt">{managerStudentFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mb-3">
              <a href="/managerstudentvisa-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Student Visa - Fresh</p>
                      </div>
                      <h4 className="amt">{managerStudentVisaFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerstudentvisa-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Student Visa - Refusal</p>
                      </div>
                      <h4 className="amt">{managerStudentVisaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerspousal-openworkfresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Open Work Permit - Fresh</p>
                      </div>
                      <h4 className="amt">{managerSpousalOpenWorkPermitFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerspousal-openworkRefusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Open Work Permit - Refusal</p>
                      </div>
                      <h4 className="amt">{managerSpousalOpenWorkPermitRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerspousal-sponsorshipfresh">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Sponsorship - Fresh</p>
                      </div>
                      <h4 className="amt">{managerSpousalSponsorshipFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerspousal-sponsorshiprefusal">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Spousal Sponsorship - Refusal</p>
                      </div>
                      <h4 className="amt">{managerSpousalSponsorshipRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerworkpermit-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Work Permit - Fresh</p>
                      </div>
                      <h4 className="amt">{managerWorkPermitFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerworkpermit-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Work Permit - Refusal</p>
                      </div>
                      <h4 className="amt">{managerWorkPermitRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managertouristvisa-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Tourist Visa - Fresh</p>
                      </div>
                      <h4 className="amt">{managerTouristVisaFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managertouristvisa-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Tourist Visa - Refusal</p>
                      </div>
                      <h4 className="amt">{managerTouristVisaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerrnip-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">RNIP (Rural and Northern Immigration Pilot) - Fresh</p>
                      </div>
                      <h4 className="amt">{managerRNIPFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerrnip-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">RNIP (Rural and Northern Immigration Pilot) - Refusal</p>
                      </div>
                      <h4 className="amt">{managerRNIPRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managervisaextensions-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa Extensions - Fresh</p>
                      </div>
                      <h4 className="amt">{managerVisaExtensionsFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managervisaextensions-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa Extensions - Refusal</p>
                      </div>
                      <h4 className="amt">{managerVisaExtensionsRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerfederalfiling-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Federal Filing - Fresh</p>
                      </div>
                      <h4 className="amt">{managerFederalFilingFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerfederalfiling-Refusal">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Federal Filing - Refusal</p>
                      </div>
                      <h4 className="amt">{managerFederalFilingRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerpnp-fresh">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">PNP (Provincial Nominee Program) Application. - Fresh</p>
                      </div>
                      <h4 className="amt">{managerPNPFreshCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerpnp-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">PNP (Provincial Nominee Program) Application. - Refusal</p>
                      </div>
                      <h4 className="amt">{managerPNPRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-12">
              <div className="separator">
                <h6>Filling Case Stages</h6>
                <div class="line"></div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/today-fillingmanager">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Today Follow-Ups Leads</p>
                      </div>
                      <h4 className="amt">{todayFillingManagerFolowUpCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managercase-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Case Initiated Date and Notes
                        </p>
                      </div>
                      <h4 className="amt">{caseInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerfile-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File Initiated or docs checklist sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{fileInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerdocs-received">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Docs received & Pending docs sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{docsReceivedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managersop-letterprepration">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Sop Or letters prepration & Forms prep
                        </p>
                      </div>
                      <h4 className="amt">{sopPreprationCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managersop-lettersent">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          SOP or Letters sent to client
                        </p>
                      </div>
                      <h4 className="amt">{sopLettersCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managerconfirmation-received">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Confirmation received on SOP , letters and docs, Forms
                          confirm...
                        </p>
                      </div>
                      <h4 className="amt">{confirmRecievedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            {/* <div className="col-md-3 mb-3">
              <a href="/managerfile-sent">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File sent for Upload or submission submission confirm...
                        </p>
                      </div>
                      <h4 className="amt">{fileSentCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div> */}

            <div className="col-md-3 mb-3">
              <a href="/managervisa-approved">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa approved</p>
                      </div>
                      <h4 className="amt">{visaApprovedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/managervisa-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa refusal</p>
                      </div>
                      <h4 className="amt">{visaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

          </div>)}

          {checkFttoken == null ? '' : (<div className="row">
            <div class="col-md-12">
              <div className="separator">
                <h6>Filling Case Stages</h6>
                <div class="line"></div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/today-fillingteam">
                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div >
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Today Follow-Ups Leads</p>
                      </div>
                      <h4 className="amt">{todayFillingTeamFolowUpCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingcase-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div onLoad={getFilingTeamData}>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Case Initiated Date and Notes
                        </p>
                      </div>
                      <h4 className="amt">{caseInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingfile-initiated">
                <div className="card radius-10  border-0 border-4 cardbg-lightblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File Initiated or docs checklist sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{fileInitiateCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingdocs-received">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Docs received & Pending docs sent{" "}
                        </p>
                      </div>
                      <h4 className="amt">{docsReceivedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingsop-letterprepration">
                <div className="card radius-10  border-0 border-4 cardbg-darkblue">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          SOP Or letters prepration & Forms prep
                        </p>
                      </div>
                      <h4 className="amt">{sopPreprationCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingsop-lettersent">
                <div className="card radius-10  border-0 border-4 cardbg-purple">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          SOP or Letters sent to client
                        </p>
                      </div>
                      <h4 className="amt">{sopLettersCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingconfirmation-received">
                <div className="card radius-10  border-0 border-4 cardbg-darkyellow">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          Confirmation received on SOP , letters and docs, Forms
                          confirm...
                        </p>
                      </div>
                      <h4 className="amt">{confirmRecievedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            {/* <div className="col-md-3 mb-3">
              <a href="/fillingfile-sent">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File sent for Upload or submission submission confirm...
                        </p>
                      </div>
                      <h4 className="amt">{fileSentCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div> */}

            {/* <div className="col-md-3 mb-3">
              <a href="/fillingfile-sent">
                <div className="card radius-10  border-0 border-4 cardbg-pink">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">
                          File sent for Upload or submission submission confirm...
                        </p>
                      </div>
                      <h4 className="amt">{fileSentCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div> */}

            <div className="col-md-3 mb-3">
              <a href="/fillingvisa-approved">
                <div className="card radius-10  border-0 border-4 cardbg-brightgreen">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa approved</p>
                      </div>
                      <h4 className="amt">{visaApprovedCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-3 mb-3">
              <a href="/fillingvisa-refusal">
                <div className="card radius-10  border-0 border-4 cardbg-red">
                  <div className="card-body">
                    <div className="d-flx-dist">
                      <div>
                        <div className="dgl2">
                          <img src={icondash1} alt="img" />
                        </div>
                        <p className="dist-title">Visa refusal</p>
                      </div>
                      <h4 className="amt">{visaRefusalCount}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>

          </div>)}

        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0', // Light gray background
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Shadow for depth
  },
  label: {
    fontSize: '15px',
    color: '#007bff', // Blue color for label
    marginBottom: '10px',
    marginRight: "11px"
  },
  select: {
    padding: '10px',
    fontSize: '12px',
    borderRadius: '5px',
    border: '2px solid #007bff', // Blue border
    marginBottom: '10px',
    width: '200px',
  },
  dateTime: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#ff4500', // Orange color for date-time
  },
  wish: {
    fontSize: '15px',
    color: '#007bff', // Blue color for label
    marginBottom: '10px',
    marginRight: "10px"
  },

  main: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: -55 }
};

export default Dashboard;
