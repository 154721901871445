import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import checklogin from "./checkLogin";
import withReactContent from "sweetalert2-react-content";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import axios from "axios";
import Swal from "sweetalert2";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { saveAs } from "file-saver";

export default function AllInfluencer({
  paginationTotalRows,
  onPageChange,
  perPage,
  onPerPageChange,
  paginationRowsPerPageOptions,
  paginationPerPageLabel,
}) {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [apiResponse, setApiResponse] = useState([]);
  const [followup, setFollowup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
  const [updatemodal, setUpdateModal] = useState(false);
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  const updatetogglecloase = () => setUpdateModal(!updatemodal);
  const totalPages = Math.ceil(paginationTotalRows / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (typeof onPageChange === "function") {
      onPageChange(pageNumber);
    }
  };

  useEffect(() => {
    checklogin();
    infomationLoginUser();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchText, startDate, endDate, totalRows]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${host}/influencer-all`, {
        params: {
          page: currentPage,
          perPage: itemsPerPage,
          searchQuery: searchText,
          startDate: startDate,
          endDate: endDate,
        },
      });
      if (response.data.success == true) {
        setTotalRows(response.data.totalRows);
        setData(response.data.data);
        setFilteredData(filterData(response.data.data));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * itemsPerPage,
      sortable: true,
      width: "100px !important",
    },
    {
      name: "Full Name",
      selector: (row) => (
        <div
          onClick={() => influencerShow(row._id)}
          style={{ cursor: "pointer" }}
        >
          <span className="text-info">{`${row.first_name} ${
            row.last_name || ""
          }`}</span>
        </div>
      ),
      sortable: true,
      width: "200px !important",
    },
    {
      name: "Email",
      selector: (row) => row.email,

      sortable: true,
      width: "250px !important",
    },
    {
      name: "Contact no.",
      selector: (row) => "+" + row.contact_number,

      sortable: true,
    },
    {
      name: "Alternate no.",
      selector: (row) =>
        row.alternate_number ? "+" + row.alternate_number : "",
      sortable: true,
    },
    {
      name: "Gender.",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => (row.country == "IN" ? "India" : "Canada"),
      sortable: true,
      width: "100px !important",
    },
    {
      name: "Password.",
      selector: (row) => row.password,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <button
            class="btn btn-primary position-relative"
            onClick={() => updateStatus(row._id, 0)}
          >
            Active
          </button>
        ) : (
          <button
            class="btn btn-danger position-relative"
            onClick={() => updateStatus(row._id, 1)}
          >
            Deactive
          </button>
        ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      width: "100px !important",
      sortable: true,
      cell: (row) => (
        <div>
          {/* Delete Icon */}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row._id)}
          >
            <i className="fas fa-trash-alt" title="Delete"></i>
          </span>
        </div>
      ),
    },
  ];

  const updateStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the status of the item.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, keep it closed",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const item = {
          _id: id,
          status: status,
        };
        const response = await axios.post(
          `${host}/influencer-byid-update-status`,
          item
        );
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      }
    });
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let data = {
          id: id,
        };
        const response = await axios.post(`${host}/DeleteRole`, data);
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      } else {
        MySwal.fire({
          icon: "warning",
          title: "warning!",
          text: `Your data successfully safe`,
        });
      }
    });
  };

  const influencerShow = async (id) => {
    try {
      const response = await axios.post(`${host}/influencer-byid`, {
        id: id,
      });
      setApiResponse(response.data);
    } catch (error) {
      console.error(error);
    }
    setFollowup(true);
  };

  const updatetoggle = (userId) => {
    axios
      .post(`${host}/influencer-byid`, { id: userId })
      .then((response) => {
        console.log(response);
        setUpdateModal(!updatemodal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const influencerDetailClose = () => {
    setFollowup(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset current page when changing items per page
    // Call function to fetch data with the new items per page setting
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const onDownload = async (e, url) => {
    e.preventDefault();
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    saveAs(url, fileName);
  };

  function onView(e, url) {
    e.preventDefault();
    window.open(url, "_blank"); // Opens the URL in a new tab
  }

  const filterData =
    data && data.data
      ? data.data.filter((row) => {
          const createdAt = new Date(row.createdAt);
          if (startDate == "" || endDate == "") {
            return Object.values(row).some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchText.toLowerCase())
            );
          } else {
            return (
              formatDate(createdAt) >= startDate && // Check if createdAt is after or equal to startDate
              formatDate(createdAt) <= endDate &&
              Object.values(row).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(searchText.toLowerCase())
              )
            );
          }
        })
      : [];

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className="row pt-3 my-8">
            <div className="col-md-12">
              <div className="wrap mt-0">
                <div style={{ display: "flex" }}>
                  <div className="search">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search"
                      style={{
                        borderRadius: "7px",
                        height: "35px",
                        marginTop: "20px",
                        borderColor: "#180A4C",
                      }}
                    />
                  </div>
                  <div>
                    <label>Start Date:</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="datepick"
                    />
                  </div>
                  <div>
                    <label>End Date:</label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="datepick"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row tab-content">
                <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                >
                  <div className="card">
                    <div className="card-header">
                      <DataTable
                        title="All CCS-partner"
                        // title="All Influencer"
                        className="custom-data-table"
                        columns={columns}
                        data={data}
                      />

                      {/* Pagination */}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span>Rows per page:</span>
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                        </select>
                        <span>{`${
                          (currentPage - 1) * itemsPerPage + 1
                        }-${Math.min(
                          currentPage * itemsPerPage
                        )} of ${totalRows}`}</span>
                        <div>
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            Previous Page
                          </button>
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={
                              (currentPage - 1) * itemsPerPage + 1 >= totalRows
                            }
                          >
                            Next Page
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Offcanvas
          className="profileCanvas"
          show={followup}
          onHide={influencerDetailClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {apiResponse && apiResponse.data && (
                <div className="profilFlex">
                  <div className="prbg">
                    <i className="fa-solid fa-user" />
                  </div>
                  <h6 className="prftitle">
                    {apiResponse.data.first_name} {apiResponse.data.last_name}{" "}
                  </h6>
                </div>
              )}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form action="">
              {apiResponse && apiResponse.data && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Name</h6>
                        <p>
                          {apiResponse.data.first_name}{" "}
                          {apiResponse.data.last_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Gender</h6>
                        <p>{apiResponse.data.gender}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Email</h6>
                        <p>{apiResponse.data.email}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Contact No.</h6>
                        <p>{apiResponse.data.contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Alt. Contact No. </h6>
                        <p>{apiResponse.data.alternate_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Country</h6>
                        <p>
                          {apiResponse.data.country == "IN"
                            ? "India"
                            : "Canada"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Password</h6>
                        <p>{apiResponse.data.password}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Address</h6>
                        <p>{apiResponse.data.address}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Social Media Channel Name</h6>
                        <p>{apiResponse.data.social_media_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Social Medial Link</h6>
                        <p>{apiResponse.data.social_media_link}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Bank Name</h6>
                        <p>{apiResponse.data.bank_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Account Number</h6>
                        <p>{apiResponse.data.account_number}</p>
                      </div>
                    </div>

                    {apiResponse.data.country === "IN" && (
                      <>
                        <div className="col-md-3">
                          <div className="namedetail">
                            <h6>IFSC Code</h6>
                            <p>{apiResponse.data.ifsc_code}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="namedetail">
                            <h6>Name Of Person</h6>
                            <p>{apiResponse.data.name_of_person}</p>
                          </div>
                        </div>
                      </>
                    )}

                    {apiResponse.data.country != "IN" && (
                      <>
                        <div className="col-md-3">
                          <div className="namedetail">
                            <h6>Institution Name</h6>
                            <p>{apiResponse.data.institution_number}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="namedetail">
                            <h6>Transit No</h6>
                            <p>{apiResponse.data.transit_no}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="namedetail">
                            <h6>Swift Code</h6>
                            <p>{apiResponse.data.swift_code}</p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6 className="">Cancelled Cheque</h6>
                        {apiResponse.data.cancelled_cheque == null ? (
                          ""
                        ) : (
                          <>
                            <img
                              src={`https://api.checkcheckservices.in/public/${apiResponse?.data?.cancelled_cheque}`}
                              style={{ width: "150px" }}
                              alt="cancelled_cheque"
                            />
                            <div className="d-flex mt-3 justify-content-center">
                              <button
                                onClick={(e) =>
                                  onView(
                                    e,
                                    `https://api.checkcheckservices.in/public/${apiResponse?.data?.cancelled_cheque}`
                                  )
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fas fa-eye"
                                  style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    lineHeight: "normal",
                                  }}
                                ></i>
                              </button>
                              <button
                                onClick={(e) =>
                                  onDownload(
                                    e,
                                    `https://api.checkcheckservices.in/public/${apiResponse?.data?.cancelled_cheque}`
                                  )
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fas fa-download"
                                  style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    lineHeight: "normal",
                                  }}
                                ></i>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Id Proof</h6>{" "}
                        {apiResponse.data.id_prrof == null ? (
                          ""
                        ) : (
                          <>
                            <img
                              src={`https://api.checkcheckservices.in/public/${apiResponse?.data?.id_prrof}`}
                              style={{ width: "150px" }}
                              alt="id_prof"
                            />

                            <div className="d-flex mt-3 justify-content-center">
                              <button
                                onClick={(e) =>
                                  onView(
                                    e,
                                    `https://api.checkcheckservices.in/public/${apiResponse?.data?.id_prrof}`
                                  )
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fas fa-eye"
                                  style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    lineHeight: "normal",
                                  }}
                                ></i>
                              </button>
                              <button
                                onClick={(e) =>
                                  onDownload(
                                    e,
                                    `https://api.checkcheckservices.in/public/${apiResponse?.data?.id_prrof}`
                                  )
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fas fa-download"
                                  style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    lineHeight: "normal",
                                  }}
                                ></i>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )}
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div>
        <Modal
          isOpen={updatemodal}
          toggle={updatetoggle}
          className="right-aligned-modal"
        >
          <ModalHeader toggle={updatetogglecloase}>Update</ModalHeader>
          <ModalBody></ModalBody>
        </Modal>
      </div>
    </>
  );
}
